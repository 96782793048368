<template>
  <footer>
  </footer>
</template>

<script>
export default {
 name: "TheFooter"
}
</script>

<style scoped>
footer{
  display:flex;  
  align-items: center;
  justify-content: center;
}
</style>